.superTitle{
    margin: 20px 0px 20px 20px;
    font-size: 55px;
    text-shadow: 1px 1px 2px grey;
    letter-spacing: 20px;
    text-align: center;
}

.bigTitle{
    margin: 10px 0px 20px 0px;
    text-transform: uppercase;
    text-align: center;
    font-size: 35px;
    letter-spacing: 8px;
    border: 2px dashed black;
    padding: 22px;
}
@media screen and (max-width: 960px) {
    .superTitle{
        font-size: 35px;
        letter-spacing: 10px;
    }
    .bigTitle{
        font-size: 18px;
    }
  }