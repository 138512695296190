* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.bookOnline,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.infoTxt{
  margin-top: "10px";
  margin-bottom: "10px";
  text-align: center;
  font-size: 28px;
  color: "rgb(61 61 61)";
}
.services {
  background-image: url('./assets/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('./assets/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.menuHormone{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 22px;
  background-color: black;
  
}

@media screen and (max-width: 960px) {
  .infoTxt{
    text-align: left;
    font-size: 18px;
  }
  .menuHormone{
    justify-content: center;
  }
}
