.MiniHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;

}
.miniHerosImage{
    height: 300px;
}
.fullHerosImage{
    display: block;
    width: 100vw;
    height: 300px;

    object-fit: cover; /* or object-fit: contain; */
}