.static_multiple_article_window{
    width: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.multiple_article_window{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.single_article_window{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.article_topTitle{
    margin: 20px 0px;
    font-size: 30px;
    letter-spacing: 2px;
    text-align: center;
    color: rgb(78, 78, 78);
}
.article_text{
    color: rgb(78, 78, 78);
    margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
    .multiple_article_window{
        width: 90%;
    }
    .article_topTitle{
        font-size: 25px;
    }
  
  }