.onlineConsulting_title{
    font-size: 20px;
    max-width: 1300px;
   
}
.onlineConsulting_title_wrapper{
    margin: 80px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.info, .onlineConsluting_bigTitle, .onlineConsluting_verification{
    margin: 50px 20px;
    padding: 20px;
    color: rgb(141, 141, 141);
    background-color: rgb(255, 243, 202);
    border-radius: 20px;
    border: 2px rgb(235, 235, 235) solid;
    text-align: center;
}
.onlineConsluting_verification{
    padding: 50px;
    font-size: 18px;
    background-color: rgb(214, 255, 202);
    border-color: rgb(158, 221, 158);
    border-radius: 0px;
}
.onlineConsluting_error{
    padding: 50px;
    font-size: 18px;
    background-color: rgb(248, 153, 153);
    border-color: rgb(158, 221, 158);
    border-radius: 0px;
}
.onlineConsulting_contactForm{
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
.paypalHider{
    background-color: green;
    height: 300px;
    width: 300px;
}
  @media screen and (max-width: 768px) {
    .onlineConsulting_contactForm{
        display: block;
    }
  
  }