.hormones_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}
.article_wrapper{
    display: flex;
    justify-content: space-around;

}
@media screen and (max-width: 768px) {

    .article_wrapper{
        flex-direction: column;
        align-items: center;
    }
  
  }