
.typeField,textArea {
    background-color: rgb(236, 236, 236);
    margin-bottom: 20px;
    width: 80%;
    height: 50px;
    font-size: 18px;
    border: 0px;
    box-shadow: 0px 3px 2px  rgba(0, 0, 0, 0.123);
  }
.textArea{
  height: 200px;
  text-indent: 2px;
}
.inputHeadTitle{
  color: rgb(98, 98, 98);
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .typeField,textArea{
    width: 100%;
  }
  .inputHeadTitle{
    padding: 0px 20px;
    text-align: left;
  }

}