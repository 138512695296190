:root{
    --transition: 0.2s
}
.link{
    text-decoration: none;
    color: #242424; 
    
}
.btnWindow{
    margin-top: 22px;
    margin-left: 10px;
    display: flex;
    padding: 0px 20px;
    justify-content: space-around;
    align-items: center;
    min-width: 250px;
    height: 50px;
    background-color: white;     
}
.white{}
.white:hover{
  transition: var(--transition);
  background-color: black;
  color: white;
}
.black{
    background-color: #242424;
    color: white;
    border: 2px solid white;
    
}
.green{
    transition: 2.2s;
    color: white;
    background-color: yellowgreen;
}
.gray{
    transition: 2.2s;
    color: #242424;
    background-color: rgb(214, 214, 214);
    border-radius: 20px;
    border: rgb(202, 202, 202) solid 2px;
    /* box-shadow: 0px 0px 22px 1px violet; */
}
.outline-white{
    transition: var(--transition);
    color: white;
    background-color: #242424;
    border: 2px solid white;
    border-radius: 20px;
}
.outline-white:hover{
    transition: var(--transition);
    background-color: white;
    color: #242424;
    
}
.underline-white{
    border-bottom: 2px solid #242424; 
}
.underline-white:hover{
    transition: var(--transition);
    background-color: black;
    color: white;
}

.outline-black{
    color: black;
    background-color: transparent;
    border: 2px solid black;
}
.outline-black:hover{
    transition: var(--transition);
    background-color: white;
    color: #242424;
     border: 2px solid white;
}

.icon{
    margin-left: 10px;
}
text{
    font-size: 22px;
}

@media screen and (max-width: 960px) {
    .btnWindow{
        margin-top: 13px;
        margin-left: 0px;
        min-width: 280px;
          
    }
    
  }